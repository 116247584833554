@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		@apply font-primary leading-tight scroll-smooth scroll-pt-16;
	}

	a {
		@apply text-blue-500 font-semibold border-b border-transparent outline-none
			hover:text-blue-700
			focus-visible:text-blue-700 focus-visible:border-blue-700 focus-visible:outline-none
			transition-[color,border-color];
	}

	th {
		@apply text-left font-regular;
	}

	img {
		@apply inline-block;
	}
}

@layer components {
	.loader {
		--loader-width: 22px;
		--loader-bar-width: 2px;
		--loader-bar-length: 6px;

		width: var(--loader-width);
		aspect-ratio: 1;
		display: grid;
		-webkit-mask: conic-gradient(from 22deg, #0003, #000);
		mask: conic-gradient(from 22deg, #0003, #000);
		animation: load 1s steps(8) infinite;
	}

	.loader,
	.loader:before {
		background: linear-gradient(
					90deg,
					currentColor 0%,
					currentColor var(--loader-bar-length),
					transparent var(--loader-bar-length),
					transparent calc(100% - var(--loader-bar-length)),
					currentColor calc(100% - var(--loader-bar-length)),
					currentColor 100%
				)
				50% / 100% var(--loader-bar-width) no-repeat,
			linear-gradient(
					0deg,
					currentColor 0%,
					currentColor var(--loader-bar-length),
					transparent var(--loader-bar-length),
					transparent calc(100% - var(--loader-bar-length)),
					currentColor calc(100% - var(--loader-bar-length)),
					currentColor 100%
				)
				50% / var(--loader-bar-width) 100% no-repeat;
	}

	.loader:before {
		content: '';
		transform: rotate(45deg);
	}

	@keyframes load {
		from {
			transform: rotate(0turn);
		}
		to {
			transform: rotate(1turn);
		}
	}
}

/**
* Styles for react-datepicker library
*
*/

/* outer container */
.react-datepicker {
	@apply relative flex items-stretch justify-center;
}

/* hide the caret that is rendered by default */
.react-datepicker__triangle {
	@apply hidden;
}

/* stretches the months to be the same height when showing 2 months in the date-range picker */
.react-datepicker__month-container {
	@apply flex flex-col items-stretch w-full;
}

/* hides "next" arrow in left month when showing 2 months in date-range picker */
.range .react-datepicker__month-container .month-next {
	@apply hidden;
}

/* hides year dropdown and "prev" arrow in right month when showing 2 months in date-range picker */
.range .react-datepicker__month-container:last-child .month-previous,
.range .react-datepicker__month-container:last-child .year-navigation {
	@apply hidden;
}

/* explicitly show the "next" arrow in right month when showing 2 months in date-range picker, was hidden by a previous rule */
.range .react-datepicker__month-container:last-child .month-next {
	@apply block;
}

/* style the container of the day names in the calender (Su, Mo, Tu, etc.) */
.react-datepicker__day-names {
	@apply bg-white pl-6 pr-4 pt-4 font-primary flex border-l border-r border-t border-gray-200 text-right;
}

/* style the individual day names in the calender (Su, Mo, Tu, etc.) */
.react-datepicker__day-name {
	@apply w-8 h-8 flex justify-center items-center font-primary text-gray-300;
}

/* 
	this hides a border on the left-hand side of the header of the right month, 
	the border logic for the calendars is a bit cheeky so as not to double up or overlap
*/
.react-datepicker__month-container + .react-datepicker__month-container .react-datepicker__day-names {
	@apply border-l-0;
}

/* month container of the actual dates, 1-31 */
.react-datepicker__month {
	@apply bg-white grow pl-6 pr-4 pt-4 pb-4 flex flex-col border-l border-r border-b border-gray-200;
}

/* some of the border logic, hide the left border on the right-hand month when showing 2 months */
.react-datepicker__month-container + .react-datepicker__month-container .react-datepicker__month {
	@apply border-l-0;
}

/* style each row of the datepicker table, equivalent of a week (sun - sat) */
.react-datepicker__week {
	@apply flex flex-row w-full py-1 items-stretch;
}

/* style each day, 1-31 in the datepicker */
.react-datepicker__day {
	@apply w-8 h-8 flex justify-center rounded-full items-center text-gray-600 font-primary text-right hover:bg-blue-100 cursor-pointer;
}

/* when using the week selector, show the week number of the month on the far-left similar to each day in the calendar */
.react-datepicker__week-number {
	@apply w-8 h-8 flex justify-center rounded-full items-center text-gray-600 text-right;
}

/* right-align the month name in the right-hand month when showing 2 months in date-range picker */
.react-datepicker__month-container + .react-datepicker__month-container .selected-date {
	@apply text-right pr-10;
}

/* selected date on individual date-picker */
.react-datepicker__day--selected {
	@apply bg-blue-500 text-white hover:bg-blue-700;
}

/* when using the week selector, and hovering, highlight the entire row (week) */
.weekpicker .react-datepicker__week:hover .react-datepicker__day {
	@apply bg-blue-500 text-white hover:bg-blue-500 rounded-none;
}

/* don't apply the above "hover state" if week selector is being used */
.weekpicker .react-datepicker__day {
	@apply hover:bg-blue-500 hover:text-white;
}

/* give first selected date a blue highlight when selecting a date-range and still need to select second date */
.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range.react-datepicker__day--selected {
	@apply bg-blue-500 text-white rounded-l-full;
}

/* give all days in the selected range a background color, except for the "ghost" days between a 2-month calendar */
.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
	@apply bg-blue-100 text-blue-800 rounded-none;
}

/* sometimes the first child (sunday) is also the last date in the selected range, that needs an extra case for the borders */
.react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range:first-child,
.react-datepicker__day--in-range.react-datepicker__day--range-end {
	@apply rounded-l-none rounded-r-full;
}

/* sometimes the last child (saturday) is also the first date in the selected range, that needs an extra case for the borders */
.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range:last-child {
	@apply rounded-l-full rounded-r-none;
}

/* when selecting end in date range, highlight dates that appear in between creating the "range" */
.react-datepicker__day--in-selecting-range.react-datepicker__day {
	@apply bg-blue-100 rounded-none;
}

/* when selecting date-range, give left-most edge of selections rounded corners */
.react-datepicker__day--in-selecting-range.react-datepicker__day:first-child,
.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month):first-child,
.react-datepicker__day--in-range.react-datepicker__day--range-start.react-datepicker__day--selected,
.react-datepicker__day--in-range.react-datepicker__day--outside-month:first-child,
.react-datepicker__day--range-start.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) {
	@apply rounded-l-full;
}

/* when selecting date-range, give right-most edge of selections rounded corners */
.react-datepicker__day--in-selecting-range.react-datepicker__day:last-child,
.react-datepicker__day--in-range:not(.react-datepicker__day--outside-month):last-child,
.react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range.react-datepicker__day--outside-month:last-child {
	@apply rounded-r-full;
}

/* let the weekpicker keep the highlighted style on the "ghost" days, since picking a 7-day period */
.weekpicker .react-datepicker__day--in-range.react-datepicker__day--outside-month {
	@apply bg-blue-100 text-blue-800 rounded-none;
}

/* apply rounded edges to first/last date in week selection */
.weekpicker .react-datepicker__day--in-range.react-datepicker__day--outside-month:first-child {
	@apply bg-blue-500 text-white rounded-l-full;
}

/* apply rounded edges to first/last date in week selection */
.weekpicker .react-datepicker__day--in-range.react-datepicker__day--outside-month:last-child {
	@apply bg-blue-500 text-white rounded-r-full;
}

/* when selecting week period, apply rounded border to far left cell */
.weekpicker .react-datepicker__week:hover .react-datepicker__day:first-child {
	@apply rounded-l-full;
}

/* when selecting week period, apply rounded border to far right cell */
.weekpicker .react-datepicker__week:hover .react-datepicker__day:last-child {
	@apply rounded-r-full;
}

/* style the first and last dates in range-selection after the selection has been made */
.react-datepicker__day--range-start.react-datepicker__day--in-range,
.react-datepicker__day--range-end.react-datepicker__day--in-range {
	@apply bg-blue-500 text-white;
}

/* make days that are shown in the month-view but part of the next/previous month in a very light gray */
.react-datepicker__day--outside-month,
.react-datepicker__day--outside-month.react-datepicker__day--in-range,
.react-datepicker__day--outside-month.react-datepicker__day--range-start.react-datepicker__day--in-range,
.react-datepicker__day--outside-month.react-datepicker__day--range-end.react-datepicker__day--in-range,
.react-datepicker__day--outside-month.react-datepicker__day--in-selecting-range,
.react-datepicker__day--outside-month.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range.react-datepicker__day--selected {
	@apply text-gray-100 hover:bg-white bg-white cursor-default pointer-events-none;
}

/* only allow the "ghost" dates to be clicked on weekpicker */
.weekpicker .react-datepicker__day--outside-month {
	@apply pointer-events-auto cursor-pointer;
}

/* do not round the days outside of the month, so that when ranges are selected across the cells the highlighted backgrounds connect instead of making repetive circles */
.react-datepicker__day--in-range.react-datepicker__day--outside-month {
	@apply rounded-none;
}

/* months and quarters pickers */
.react-datepicker__monthPicker,
.react-datepicker__quarterPicker {
	@apply pl-4 pr-4 border-t border-gray-200;
}

/* show the month and quarter selections in a grid */
.react-datepicker__month-wrapper,
.react-datepicker__quarter-wrapper {
	@apply flex items-center justify-center;
}

/* center align the text in the buttons for the quarters and months */
.react-datepicker__month-text,
.react-datepicker__quarter-text {
	@apply cursor-pointer flex items-center justify-center transition hover:bg-blue-100 font-primary;
}

/* give some size to the month selection buttons */
.react-datepicker__month-text {
	@apply w-16 h-10;
}

/* give some size to the quarter selection buttons */
.react-datepicker__quarter-text {
	@apply w-1/2 h-12;
}

/* style the month and quarter when its selected */
.react-datepicker__month--selected,
.react-datepicker__quarter--selected {
	@apply bg-blue-500 text-white;
}

/**
* Timepicker styles
*/

/* style the form input the datepicker library renders */
.timepicker .react-datepicker__input-container input {
	@apply cursor-pointer block border rounded-sm font-primary border-gray-400 transition-colors h-8 text-p3 px-2 w-32;
}

/* style the form input the datepicker library renders when disabled*/
.timepicker .react-datepicker__input-container input:disabled {
	@apply cursor-not-allowed;
}

/* style the "popper" background */
.timepicker .react-datepicker-popper {
	@apply pt-3 pl-3 bg-white font-primary flex border border-gray-200 text-right drop-shadow w-32 z-10;
}

/* make the list of times scrollable */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
	@apply pb-3 pr-3 h-64 overflow-y-scroll;
}

/* make the time dropdown header look like our other form labels */
.react-datepicker-time__header {
	@apply text-p3 text-gray-700 tracking-wider uppercase text-left;
}

/* style each option in the time dropdown */
.react-datepicker__time-list-item {
	@apply py-1 px-2 pr-7 rounded hover:bg-blue-50 hover:text-blue-500 my-1 cursor-pointer text-i2;
}

/* give the selected time option an "active" state */
.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
	@apply text-blue-500 bg-blue-50;
}

/* hide all times outside of the allowed selectable range */
.react-datepicker__time-list-item--disabled {
	@apply hidden;
}

.heatmapCellDark:hover {
	box-shadow: rgb(0, 0, 0) 0px 0px 0px 1.2px inset;
}
.heatmapCellLight:hover {
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px inset;
}

.react-calendar-heatmap .color-empty {
	fill: #f5f6fa; /* You can change this color for empty blocks */
}

.react-calendar-heatmap .color-scale-0 {
	fill: #f5f6fa; /* You can change this color for empty blocks */
}

.react-calendar-heatmap .color-scale-1 {
	fill: #bfd7ff;
}

.react-calendar-heatmap .color-scale-2 {
	fill: #97c5ff;
}

.react-calendar-heatmap .color-scale-3 {
	fill: #91c0ff;
}

.react-calendar-heatmap .color-scale-release {
	fill: #ffb85c;
}

.react-calendar-heatmap .color-scale-automatic_release {
	fill: #ec665f;
}

.react-calendar-heatmap .color-scale-used {
	fill: #8fdbbe;
}

.react-calendar-heatmap .color-scale-4 {
	fill: #3f76ff;
}

.react-calendar-heatmap .color-scale-util-passed-1 {
	fill: hsl(157 59% 96%);
}

.react-calendar-heatmap .color-scale-util-passed-2 {
	fill: hsl(157 55% 92%);
}

.react-calendar-heatmap .color-scale-util-passed-3 {
	fill: hsl(157 60% 86%);
}

.react-calendar-heatmap .color-scale-util-passed-4 {
	fill: hsl(157 59% 79%);
}

.react-calendar-heatmap .color-scale-util-passed-5 {
	fill: hsl(157 51% 71%);
}

.react-calendar-heatmap .color-scale-util-passed-6 {
	fill: hsl(157 59% 53%);
}

.react-calendar-heatmap .color-scale-util-passed-7 {
	fill: hsl(167 73% 41%);
}

.react-calendar-heatmap .color-scale-util-failed-1 {
	fill: hsl(6, 78%, 57%, 0.7);
}

.react-calendar-heatmap .color-scale-util-failed-2 {
	fill: hsl(6, 78%, 57%, 0.6);
}

.react-calendar-heatmap .color-scale-util-failed-3 {
	fill: hsl(6, 78%, 57%, 0.5);
}

.react-calendar-heatmap .color-scale-util-failed-4 {
	fill: hsl(6, 78%, 57%, 0.4);
}

.react-calendar-heatmap .color-scale-util-failed-5 {
	fill: hsl(6, 78%, 57%, 0.3);
}

.react-calendar-heatmap .color-scale-util-failed-6 {
	fill: hsl(6, 78%, 57%, 0.2);
}

.react-calendar-heatmap .color-scale-util-failed-7 {
	fill: hsl(6, 78%, 57%, 0.1);
}

.react-calendar-heatmap .color-scale-release-1 {
	fill: hsl(34 100% 93%);
}

.react-calendar-heatmap .color-scale-release-2 {
	fill: hsl(34 100% 88%);
}

.react-calendar-heatmap .color-scale-release-3 {
	fill: hsl(34 100% 81%);
}

.react-calendar-heatmap .color-scale-release-4 {
	fill: hsl(34 100% 74%);
}

.react-calendar-heatmap .color-scale-release-5 {
	fill: hsl(34 100% 68%);
}

.react-calendar-heatmap .color-scale-release-6 {
	fill: hsl(34 100% 59%);
}

.react-calendar-heatmap .color-scale-automatic-1 {
	fill: hsl(221, 15%, 25%, 0.1);
}

.react-calendar-heatmap .color-scale-automatic-2 {
	fill: hsl(221, 15%, 25%, 0.2);
}

.react-calendar-heatmap .color-scale-automatic-3 {
	fill: hsl(221, 15%, 25%, 0.3);
}

.react-calendar-heatmap .color-scale-automatic-4 {
	fill: hsl(221, 15%, 25%, 0.4);
}

.react-calendar-heatmap .color-scale-automatic-5 {
	fill: hsla(221, 15%, 25%, 0.5);
}

.react-calendar-heatmap .color-scale-automatic-6 {
	fill: hsla(221, 15%, 25%, 0.6);
}

.react-calendar-heatmap .color-scale-automatic-7 {
	fill: hsl(221, 15%, 25%, 0.7);
}

.react-calendar-heatmap .color-scale-fill-rate-1 {
	fill: #eeddfd;
}

.react-calendar-heatmap .color-scale-fill-rate-2 {
	fill: #dcbbfc;
}

.react-calendar-heatmap .color-scale-fill-rate-3 {
	fill: #cb99fa;
}

.react-calendar-heatmap .color-scale-fill-rate-4 {
	fill: #ba77f9;
}

.react-calendar-heatmap .color-scale-fill-rate-5 {
	fill: #a855f7;
}

.react-calendar-heatmap .color-scale-fill-rate-6 {
	fill: #8815f4;
}

.react-calendar-heatmap .color-scale-fill-rate-7 {
	fill: #6609be;
}

.react-calendar-heatmap .color-huddle-scale-1 {
	fill: #eff6ff;
}

.react-calendar-heatmap .color-huddle-scale-2 {
	fill: #eff6ff;
}

.react-calendar-heatmap .color-huddle-scale-3 {
	fill: #bfdbfe;
}

.react-calendar-heatmap .color-huddle-scale-4 {
	fill: #bfdbfe;
}

.react-calendar-heatmap .color-huddle-scale-5 {
	fill: #60a5fa;
}

.react-calendar-heatmap .color-huddle-scale-6 {
	fill: #60a5fa;
}

.react-calendar-heatmap .color-huddle-scale-7 {
	fill: #2563eb;
}

.react-calendar-heatmap .color-huddle-scale-8 {
	fill: #2563eb;
}

.react-calendar-heatmap .color-huddle-scale-9 {
	fill: #1e40af;
}

/* 'violet': {
	'50': '#f5f3ff',
	'100': '#ede9fe',
	'200': '#ddd6fe',
	'300': '#c4b5fd',
	'400': '#a78bfa',
	'500': '#8b5cf6',
	'600': '#7c3aed',
	'700': '#6d28d9',
	'800': '#5b21b6',
	'900': '#4c1d95',
	'950': '#2e1065',
}, */

.react-calendar-heatmap .color-huddle-scale-schedule-1 {
	fill: #f5f3ff;
}

.react-calendar-heatmap .color-huddle-scale-schedule-2 {
	fill: #ede9fe;
}

.react-calendar-heatmap .color-huddle-scale-schedule-3 {
	fill: #ddd6fe;
}

.react-calendar-heatmap .color-huddle-scale-schedule-4 {
	fill: #c4b5fd;
}

.react-calendar-heatmap .color-huddle-scale-schedule-5 {
	fill: #a78bfa;
}

.react-calendar-heatmap .color-huddle-scale-schedule-6 {
	fill: #8b5cf6;
}

.react-calendar-heatmap .color-huddle-scale-schedule-7 {
	fill: #7c3aed;
}

.react-calendar-heatmap .color-huddle-scale-schedule-8 {
	fill: #6d28d9;
}

.react-calendar-heatmap .color-huddle-scale-schedule-9 {
	fill: #5b21b6;
}

.react-calendar-heatmap rect {
	width: 0.7em; /* Adjust the width to your preferred size */
	height: 0.7em; /* Adjust the height to your preferred size */
}

.react-calendar-heatmap svg {
	font-size: 0; /* Remove the whitespace between inline elements */
}
.react-calendar-heatmap text {
	font-size: 0.5em; /* Reset the font size for the text elements */
}

.react-calendar-heatmap {
	height: 12em;
	border: '1px solid black';
	padding: '0px';
	margin: '0px';
	margin-top: 1.2em;
}

.react-calendar-heatmap rect:hover {
	stroke: #555;
	stroke-width: 1px;
	cursor: pointer;
}

/* tooltipStyles.css */
.react-tooltip {
	background-color: red;
	color: white;
	border-radius: 5px;
	padding: 5px;
}

/* Define custom scrollbar styles */
.customscrollbar::-webkit-scrollbar {
	width: 7px;
}
.customscrollbar::-webkit-scrollbar-track {
	background: #f0f9ff;
	border-radius: 5px;
}
.customscrollbar::-webkit-scrollbar-thumb {
	background: #106ff4;
	border-radius: 5px;
}

/* Define custom scrollbar styles */
.slate-customscrollbar::-webkit-scrollbar {
	width: 5px;
}
.slate-customscrollbar::-webkit-scrollbar-track {
	background: #f0f9ff;
	border-radius: 5px;
}
.slate-customscrollbar::-webkit-scrollbar-thumb {
	background: #cbd5e1;
	border-radius: 5px;
}
